import { ReactNode, useCallback } from "react";
import {
  GridColDef, GridEventListener, GridFilterModel, GridSortModel, getGridDateOperators, getGridStringOperators
} from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { ContactTask } from "../../../store/cabinetApi/generated/crm";
import CRMTable from '../CRMTable';
import { Box, SxProps } from "@mui/material";


const taskColumns: GridColDef<ContactTask>[] = [
  {
    field: 'title',
    headerName: 'Title',
    type: 'string',
    display: "flex",
    flex: 2,
    minWidth: 250,
    filterOperators: getGridStringOperators().filter(op => !['isAnyOf', 'isEmpty', 'istaskmpty'].includes(op.value)),
  },
  {
    field: 'created_at',
    headerName: 'Created On',
    type: 'dateTime',
    display: "flex",
    width: 200,
    filterOperators: getGridDateOperators(false).filter(op => ['onOrAfter', 'onOrBefore'].includes(op.value)),
    valueGetter: (value, row, column, apiRef) => (
      DateTime.fromISO(value).toJSDate()
    ),
    valueFormatter: (value, row, column, apiRef) => (
      DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_MED)
    ),
  },
];

const ReminderTable = ({
  tasks, onNavigateToContact, page, pageSize, rowCount, onPageChange, filter, onSetFilter,
  sort, onSetSort, loading, interactive, paginate, header, footer, defaultHiddenFields, sx
}: {
  tasks: ContactTask[];
  header?: ReactNode;
  footer?: ReactNode;
  page: number;
  pageSize: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  onNavigateToContact: (id: Required<ContactTask>['contacts'][number]) => void;
  filter?: GridFilterModel;
  onSetFilter: (filter: GridFilterModel | undefined) => void;
  sort?: GridSortModel;
  onSetSort: (filter: GridSortModel | undefined) => void;
  loading: boolean;
  interactive: boolean;
  paginate: boolean;
  defaultHiddenFields?: string[];
  sx?: SxProps;
}) => {
  const handleCellClick = useCallback<GridEventListener<'cellClick'>>((params, event, details) => {
    if (params.field === 'name') {
      onNavigateToContact(params.row.contacts[0]?.id);
    }
  }, [onNavigateToContact]);

  return (
    <>
      {header}
      <Box sx={sx}>
        <CRMTable
          columns={taskColumns}
          rows={tasks}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          onPageChange={onPageChange}
          onCellClick={handleCellClick}
          filter={filter}
          onSetFilter={onSetFilter}
          sort={sort}
          onSetSort={onSetSort}
          loading={loading}
          interactive={interactive}
          paginate={paginate}
          defaultHiddenFields={defaultHiddenFields}
        />
      </Box>
      {footer}
    </>
  );
};

export default ReminderTable;
