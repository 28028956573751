import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import CabinetPage from "../../components/Common/CabinetPage";
import CRMContactsTable from "../../components/CRM/CRMContacts/CRMContacts";
import CRMCompaniesTable from "../../components/CRM/CRMCompanies/Companies";
import CRMNotesTable from "../../components/CRM/CRMNotes/CRMNotes";
import CRMReminderTable from "../../components/CRM/CRMReminders/CRMReminders";
import { CabButton } from "@CabComponents/CabButton";
import SearchInputForm, { SearchForm } from "../../components/CRM/SearchInputForm";
import CabSpinner from "@CabComponents/CabSpinner";
import useContactsList from "../../components/CRM/CRMContacts/contactsListHook";
import useCompaniesList from "../../components/CRM/CRMCompanies/companiesListHook";
import useNotesList from "../../components/CRM/CRMNotes/notesListHook";
import useTasksList from "../../components/CRM/CRMReminders/remindersListHook";


const CRMSearchView = ({
  search, entityFilter, onSearch,
  contactsListData, companiesListData, notesListData, tasksListData
}: {
  search?: string;
  entityFilter?: string[];
  contactsListData: ReturnType<typeof useContactsList>;
  companiesListData: ReturnType<typeof useCompaniesList>;
  notesListData: ReturnType<typeof useNotesList>;
  tasksListData: ReturnType<typeof useTasksList>;
  onSearch: (search: string, entities?: string[]) => void;
}) => {
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const onlyContacts = !!(entityFilter && entityFilter.length === 1 && entityFilter.includes('contacts'));
  const onlyCompanies = !!(entityFilter && entityFilter.length === 1 && entityFilter.includes('companies'));
  const onlyNotes = !!(entityFilter && entityFilter.length === 1 && entityFilter.includes('notes'));
  const onlyReminders = !!(entityFilter && entityFilter.length === 1 && entityFilter.includes('reminders'));

  const showNoResults = contactsListData.rowCount === 0
    && companiesListData.rowCount === 0
    && notesListData.rowCount === 0
    && tasksListData.rowCount === 0;

  const showSpinner = contactsListData.loading
    || companiesListData.loadingCompanies
    || notesListData.loading
    || tasksListData.loading;

  const handleSearch = ({ search: s }: SearchForm) => {
    onSearch(s);
  };

  return (
    <CabinetPage
      pageName="Search"
      headerContent={
        <Box width='100%' display='flex' justifyContent='space-between' alignItems="center">
          <Typography variant="h1">
            {search ? `Results for "${search}"` : 'Results'}
          </Typography>
          <Box display="flex" alignItems="center" flex={1} justifyContent="end">
            {showSpinner && (
              <Box>
                <CabSpinner scale={1} />
              </Box>
            )}
            {!isSmDown && (
              <SearchInputForm
                defaultSearch={search}
                onSearch={handleSearch}
                sx={{ flex: 1, maxWidth: 500, paddingLeft: 2 }}
              />
            )}
          </Box>
        </Box>
      }
    >
      <Box padding={2} display="flex" flexDirection="column" flex={1} gap={2}>
        {isSmDown && (
          <SearchInputForm onSearch={handleSearch} sx={{ display: 'flex', justifyContent: 'end', width: '100%' }} />
        )}

        <Box display="flex" flex={1} flexDirection="column" gap={8}>
          {showNoResults && (
            <Typography width="100%" textAlign="center" marginTop={8}>
              No results matched your search
            </Typography>
          )}

          {((!entityFilter || onlyContacts) && contactsListData.rowCount > 0) && (
            <Box {...(onlyContacts ? { position: "relative", height: "100%" } : {})}>
              <CRMContactsTable
                {...contactsListData}
                header={<Typography variant="h2" fontSize={20} sx={{ marginBottom: 1 }}>Contacts</Typography>}
                footer={!onlyContacts && (
                  <CabButton
                    onClick={() => onSearch(search || '', ['contacts'])}
                    buttonType="tertiary"
                    sx={{ marginTop: 2 }}
                  >
                    View All Contacts
                  </CabButton>
                )}
                interactive={onlyContacts}
                paginate={onlyContacts}
                defaultHiddenFields={['created_at']}
                sx={onlyContacts ? { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 } : undefined}
              />
            </Box>
          )}

          {((!entityFilter || onlyCompanies) && companiesListData.rowCount > 0) && (
            <Box {...(onlyCompanies ? { position: "relative", height: "100%" } : {})}>
              <CRMCompaniesTable
                {...companiesListData}
                header={<Typography variant="h2" fontSize={20} sx={{ marginBottom: 1 }}>Companies</Typography>}
                footer={!onlyCompanies && (
                  <CabButton
                    onClick={() => onSearch(search || '', ['companies'])}
                    buttonType="tertiary"
                    sx={{ marginTop: 2 }}
                  >
                    View All Companies
                  </CabButton>
                )}
                interactive={onlyCompanies}
                paginate={onlyCompanies}
                sx={onlyCompanies ? { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 } : undefined}
              />
            </Box>
          )}

          {((!entityFilter || onlyNotes) && notesListData.rowCount > 0) && (
            <Box {...(onlyNotes ? { position: "relative", height: "100%" } : {})}>
              <CRMNotesTable
                {...notesListData}
                header={<Typography variant="h2" fontSize={20} sx={{ marginBottom: 1 }}>Notes</Typography>}
                footer={!onlyNotes && (
                  <CabButton
                    onClick={() => onSearch(search || '', ['notes'])}
                    buttonType="tertiary"
                    sx={{ marginTop: 2 }}
                  >
                    View All Notes
                  </CabButton>
                )}
                interactive={onlyNotes}
                paginate={onlyNotes}
                defaultHiddenFields={['created_at']}
                sx={onlyNotes ? { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 } : undefined}
              />
            </Box>
          )}

          {((!entityFilter || onlyReminders) && tasksListData.rowCount > 0) && (
            <Box {...(onlyReminders ? { position: "relative", height: "100%" } : {})}>
              <CRMReminderTable
                {...tasksListData}
                header={<Typography variant="h2" fontSize={20} sx={{ marginBottom: 1 }}>Reminders</Typography>}
                footer={!onlyReminders && (
                  <CabButton
                    onClick={() => onSearch(search || '', ['reminders'])}
                    buttonType="tertiary"
                    sx={{ marginTop: 2 }}
                  >
                    View All Reminders
                  </CabButton>
                )}
                interactive={onlyReminders}
                paginate={onlyReminders}
                defaultHiddenFields={['created_at']}
                sx={onlyReminders ? { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 } : undefined}
              />
            </Box>
          )}
        </Box>
      </Box>
    </CabinetPage>
  );
};


export default CRMSearchView;
