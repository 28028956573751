import CRMSearchView from './CRMSearch';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { PAGE_URL } from '../../constants';
import useContactsList from '../../components/CRM/CRMContacts/contactsListHook';
import useCompaniesList from '../../components/CRM/CRMCompanies/companiesListHook';
import useNotesList from '../../components/CRM/CRMNotes/notesListHook';
import useTasksList from '../../components/CRM/CRMReminders/remindersListHook';


const CRMSearchContainer = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const search = params.get('search') || undefined;
  const entities = params.getAll('entities');

  const onlyContacts = !!(entities.length === 1 && entities.includes('contacts'));
  const onlyCompanies = !!(entities.length === 1 && entities.includes('companies'));
  const onlyNotes = !!(entities.length === 1 && entities.includes('notes'));
  const onlyReminders = !!(entities.length === 1 && entities.includes('reminders'));

  const contactsListData = useContactsList({ pageSize: onlyContacts ? 20 : 5, search });
  const companiesListData = useCompaniesList({ pageSize: onlyCompanies ? 20 : 5, search });
  const notesListData = useNotesList({ pageSize: onlyNotes ? 20 : 5, search });
  const tasksListData = useTasksList({ pageSize: onlyReminders ? 20 : 5, search });

  const handleSearch = (s: string, entityFilter?: string[] ) => {
    navigate({
      pathname: PAGE_URL.CRM_SEARCH,
      search: createSearchParams({
        search: s,
        ...(entityFilter ? { entities: entityFilter } : {})
      }).toString(),
    });
  };

  return <CRMSearchView
    search={search}
    onSearch={handleSearch}
    entityFilter={entities.length > 0 ? entities : undefined}
    contactsListData={contactsListData}
    companiesListData={companiesListData}
    notesListData={notesListData}
    tasksListData={tasksListData}
  />;
};


export default CRMSearchContainer;
