import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";
import { PAGE_URL } from "../../../constants";
import { crm } from "../../../store/cabinetApi/crm";
import { transformMuiDataGridFilterSortToApiModel } from "../../../utils/dataUtils";
import { AppCrmContactNotesListApiArg, ContactNote } from "../../../store/cabinetApi/generated/crm";
import { useDeepCompareEffect } from "react-use";


type NotesApiFilter = Omit<AppCrmContactNotesListApiArg, 'offset'|'limit'>;

const useNotesList = ({ pageSize, defaultFilter = {items: []}, defaultSort, search }: {
  pageSize: number; defaultFilter?: GridFilterModel; defaultSort?: GridSortModel, search?: string
}) => {
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState<GridFilterModel>(defaultFilter || { items: [] });
  const [sort, setSort] = useState<GridSortModel>(
    defaultSort || [{ field: 'created_at', sort: 'desc' }]
  );

  const apiFilter: NotesApiFilter = useMemo(() => {
    return transformMuiDataGridFilterSortToApiModel({ filterModel: filter, sortModel: sort }, true);
  }, [filter, sort]);

  const { data: notesData, isFetching: loadingNotes } = crm.useAppCrmContactNotesListQuery({
    limit: pageSize, offset: page * pageSize, search,
    ...apiFilter,
  });

  const navigate = useNavigate();

  const handleNavigateToContact = (contactId: Required<ContactNote>['contacts'][number]) => (
    navigate(`${PAGE_URL.CRM_PEOPLE}/${contactId}`)
  );

  const handleSetFilter = (fltr: GridFilterModel | undefined) => {
    setPage(0);
    setFilter(fltr || { items: [] });
  };

  const handleSetSort = (srt: GridSortModel | undefined) => {
    setPage(0);
    setSort(srt || []);
  };

  useDeepCompareEffect(() => setFilter(defaultFilter || { items: [] }), [defaultFilter]);
  // reset filter anytime search changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setFilter(defaultFilter || { items: [] }), [search]);

  return {
    notes: notesData?.data || [],
    rowCount: notesData?.meta?.count || 0,
    loading: loadingNotes,
    filter,
    sort,
    page,
    pageSize,
    onNavigateToContact: handleNavigateToContact,
    onSetFilter: handleSetFilter,
    onSetSort: handleSetSort,
    onPageChange: setPage,
  };
};

export default useNotesList;
